'use strict';

/** 
 * jobsOfCookApp boostrap!
 */

$(document).foundation();


var body            = $('body'),
    gNavi           = $('#globalNav'),
    gNaviCloseBtn   = $('#closeButton'),
    visualImages    = $('[id=visualImage]'),
    jobsCounter     = $('#jobsCount'),
    loadDataPanel   = $('#loadDataPanel'),
    searchTopPanel  = $('#searchTopPanel'),
    searchPanel     = $('#searchPanel'),
    searchResultPanel = $('#searchResultPanel'),
    pageTopButton   = $('#pageTop'),
    globalHeader    = $('#globalHeader'),
    itemLink        = $('[id=itemLink]'),
    linkTo          = $('[id=linkToAnchor]'),
    jobsItem        = $('[id=jobsItem]');

var currentScrollY,
    switchFlag = true;

var scrollTag = 'html'; 
if(window.chrome || 'WebkitAppearance' in document.documentElement.style) {
    if(!window.chrome) {
        scrollTag = 'body'; 
    }
}

var jobsOfCookAppModule = {
    'init': function() {
        this.abs = 0;
        this.images = [];
        this._startApp();
    },

    '_startApp': function() {
        //loadDataPanel.hide();
        //this._imgPreload();
        //this._gnav();
        var windowWidth = $(window).width();
        var windowSm = 770;
        if (windowWidth <= windowSm) {
            searchResultPanel.hide();
        }else{
            //searchResultPanel.hide();
        }

        this._startMainVisual();
        this._startSearchPanel();
        this._startJobsCount();
        this._startLinkToAnchor();
        this._startGoPageTop();
        this._itemLink();
        //this._itemScaleIn();
    },
    '_startSearchPanel': function() {
        var windowWidth = $(window).width();
        var windowSm = 770;
        if (windowWidth <= windowSm) {
            searchResultPanel.hide();
        } else {
            //searchResultPanel.hide();
        }
    },
    '_gnav': function() {
        gNavi.click(function() {
            if(switchFlag) {
                currentScrollY = $(window).scrollTop();
                body.css( {
                    position: 'fixed',
                    width: '100%',
                    top: -1 * currentScrollY
                });
            } else {
                body.css('position', 'static');
            }
            switchFlag = !switchFlag;
        });
        gNaviCloseBtn.click(function() {
            body.css('position', 'static');
            switchFlag = true;
        });
    },
    '_imgPreload': function() {
        var i = 0,
            l = this.images.length;
        for(; i < l; i++) {
            $("<img>").attr("src", arguments[i]);
        }
    },

    '_startMainVisual': function() {
         setTimeout(function() {
            visualImages.removeClass('hide');
        }, 2000);
    },

    '_startJobsCount': function() {
        
        jobsCounter.each(function () {
            $(this).prop('Counter',0).animate({
                Counter: $(this).text()
            }, {
                duration: 1000,
                easing: 'swing',
                step: function (now) {
                    $(this).text(Math.ceil(now));
                }
            });
        });
    },

    '_startLinkToAnchor': function() {
        
        linkTo.click(function(e) {
            e.preventDefault();
            var speed = 300;
            var target= $(this).attr("href");
            var position = $(target).offset().top - globalHeader.height() - 15;
            $(scrollTag).animate({scrollTop:position}, speed, 'swing');
            return false;
        });

    },
    '_startGoPageTop': function() {
        
        pageTopButton.hide();

        $(window).scroll(function() {
            if($(this).scrollTop() > 80) {
                pageTopButton.fadeIn();
            }else {
                pageTopButton.fadeOut();
            } 
        });

        var eventTrigger = 'ontouchstart' in window ? 'touchend' : 'click';
        //var eventTrigger = 'click';
        
        pageTopButton.on(eventTrigger, function() {
            $(scrollTag).animate({
                scrollTop: 0
            }, 500);
            return false;
        });
    },
    '_itemLink': function() {
        itemLink.click(function(e) {
            e.preventDefault();
            var href= $('#itemLinkTarget', this).attr('href');
            location.href = href;
        });
    },
    '_itemScaleIn': function() {
        jobsItem.each(function(i) {
            Foundation.Motion.animateIn(this, 'scale-in-up');
        });
    }
}


$(document).ready(function() {
    jobsOfCookAppModule.init();
});













//issue todo - convert to jquery
new function() {
    var footerId = "footer",
        fixedFooter = $('#footer');
    
    function footerFixed() {
        var dh = body.height();
        fixedFooter.css('top', '0px');
        
        var ft = fixedFooter.offset().top,
            fh = fixedFooter.outerHeight();
        
        if (window.innerHeight) {
            var wh = window.innerHeight;
        } else if(document.documentElement && document.documentElement.clientHeight != 0){
            var wh = document.documentElement.clientHeight;
        }
        if(ft + fh < wh) {
            fixedFooter.css('position', 'relative');
            fixedFooter.css('top', (wh-fh-ft-1)+'px');
        }
    }

    function checkFontSize(func) {
        var e = document.createElement("div");
        var s = document.createTextNode("S");
        e.appendChild(s);
        e.style.visibility="hidden"
        e.style.position="absolute"
        e.style.top="0"
        document.body.appendChild(e);
        var defHeight = e.offsetHeight;

        function checkBoxSize() {
            if(defHeight != e.offsetHeight) {
                func();
                defHeight= e.offsetHeight;
            }
        }
        setInterval(checkBoxSize,1000)
    }

    function addEvent(elm,listener,fn) {
        try{
            elm.addEventListener(listener,fn,false);
        }catch(e){
            elm.attachEvent("on"+listener,fn);
        }
    }

    addEvent(window,"load",footerFixed);
    addEvent(window,"load",function() {
        checkFontSize(footerFixed);
    });
    addEvent(window,"resize",footerFixed);

}



